import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"

export default function Template({ data }) {
  const { datoCmsLegal } = data
  return (
    <Layout>
      <main className={"legal container px-4 mx-auto py-24"}>
        <div
          className={"prose-xl max-w-none"}
          dangerouslySetInnerHTML={{ __html: datoCmsLegal.contenu }}
        />
      </main>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($slug: String!) {
    datoCmsLegal(slug: { eq: $slug }) {
      contenu
      contenuNode {
        internal {
          content
          owner
          type
          ignoreType
          mediaType
          fieldOwners
          description
          contentFilePath
          contentDigest
        }
        id
      }
      gatsbypreview
      id
      locale
      meta {
        status
      }
      slug
      titre
    }
  }
`

export const Head = ({ data, location }) => {
  const { datoCmsLegal } = data
  return <Seo canonical={location.pathname} title={datoCmsLegal.titre} />
}
